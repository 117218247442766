import React  from "react"
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet"; 
import { navigate } from "gatsby"  
import { ChevronLeftIcon } from "@heroicons/react/solid";

const FormularPage = ({location}) => {
      

  return (
  <>
       <Helmet>
      <meta charSet="utf-8" />
      <title>Formulář odeslán | Exekutorský úřad Praha 9</title> 
    </Helmet>
      <Layout location={location} pageContext={{title: 'Formulář odeslán'}}> 
   

      
<main className="pb-8">
<div className="mx-auto w-full">
        <div className="mx-auto max-w-6xl w-full  py-16 px-10 ">    
           <div className=" pt-20 prose prose-sm">
               <h1>Formulář odeslán</h1>
                      <div className="mb-10"><p>Děkujeme za vyplnění formuláře.</p> </div>
         </div> 
         <div>  
               <div className="pt-8">
                    <div> 
                      <button onClick={() => navigate(-1)} className="bg-gray-500 mb-20  text-white font-bold py-4 px-4  rounded hover:bg-gray-600 inline-flex pr-10 items-center">
                         <ChevronLeftIcon className="w-7 h-7 mr-5" /><span>Zpět</span>
                      </button>

                    </div>
                    
                  </div>
 
    </div>
    </div>
    </div>
  </main>
    </Layout>
  </>
  )
}

export default FormularPage
 